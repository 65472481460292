import { useEffect, useState } from "react";

const useGetSpecialists = (slug, firebase) => {
  const [specialists, setSpecialists] = useState([]);

  useEffect(() => {
    if (slug) {
      firebase.db
        .collection("publicSpecialistProfile")
        .where("speciality", "==", slug)
        .where("isActive", "==", true)
        .get()
        .then(querySnapshot => {
          let l_speclalists = [];
          querySnapshot.forEach(docRef => {
            l_speclalists.push({ id: docRef.id, ...docRef.data() });
          });
          setSpecialists(l_speclalists);
        });
    }
  }, [slug]);
  return specialists;
};

export { useGetSpecialists };
