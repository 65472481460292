import { useEffect, useState } from "react";

export const useGetConsultationList = firebase => {
  const [consultationDataList, setConsultationDataList] = useState([]);

  useEffect(() => {
    if (firebase && firebase.auth && firebase.auth.currentUser) {
      firebase.db
        .collection("chats")
        .where("clientId", "==", firebase.auth.currentUser.uid)
        .where("isActive", "==", true)
        .onSnapshot(querySnapshot => {
          let chats = [];
          querySnapshot.forEach(function(doc) {
            chats.push({ id: doc.id, ...doc.data() });
          });
          setConsultationDataList(chats);
        });
    }
  }, [firebase, firebase.auth, firebase.auth.currentUser]);

  return consultationDataList;
};

export const useFinishConsultation = (firebase, chatId) => {
  const [id, setId] = useState(null);
  useEffect(() => {
    setId(chatId);
  }, [chatId, firebase]);

  const finishConsultation = async () => {
    await firebase.db.collection(`chats/${id}/actions`).add({
      type: "FINISH_CONSULTATION",
      created_by: firebase.auth.currentUser.uid,
      isClient: true,
      timestamp: firebase.FieldValue.serverTimestamp()
    });
  };

  const finishConsultationWithId = async consultationId => {
    await firebase.db.collection(`chats/${consultationId}/actions`).add({
      type: "FINISH_CONSULTATION",
      created_by: firebase.auth.currentUser.uid,
      isClient: true,
      timestamp: firebase.FieldValue.serverTimestamp()
    });
  };

  return { finishConsultation, finishConsultationWithId };
};
