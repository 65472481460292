import React, { useContext, useEffect } from "react";

import Container from "react-bootstrap/Container";
import { Route, withRouter, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import AppNavBar from "./Components/NavBar";
import AboutPage from "./Pages/ServicePage/AboutPage";
import PrivacyPolicyPage from "./Pages/ServicePage/PrivacyPolicyPage";
import ConsultationPage from "./Pages/ConsultationPage";
import ChatPage from "./Pages/ChatPage";
import HistoryDetailPage from "./Pages/RecomendationHistoryPage/HistoryDetailPage";
import HistoryPage from "./Pages/RecomendationHistoryPage";
// import HomePage from "./Pages/HomePage";
import UserProfileEdit from "./Pages/UserProfileEdit";
import { LoginPage, LoginVerifyEmailLinkPage } from "./Pages/LoginPage";
import ActiveConsultationsContext, { UserProfileContext } from "./context";
import PersonalDataProcessingAgreementModal from "./Components/PersonalDataProcessingAgreementModal";

import {
  SpecialistDetailPage,
  SpecialistListPage
} from "./Pages/SpecialistPage";
import SpecialitiesPage from "./Pages/SpecialitiesPage";

import {
  useListenUserAuthState,
  useClientProfile,
  useGetActiveChatsWIthProfileId,
  useAcceptPersonalDataProcessingAgreementAndTerms
} from "./hooks";
import { FirebaseContext } from "./Firebase";

const App = ({ location, history }) => {
  const firebase = useContext(FirebaseContext);
  const user = useListenUserAuthState(firebase.auth);

  const logout = async () => {
    if (firebase) {
      await firebase.auth.signOut();
    }
  };

  useEffect(() => {
    if (user) {
      if (
        location.pathname === "/" ||
        location.pathname === "/login/" ||
        location.pathname === "/login/verify/"
      ) {
        return history.replace(`/specialities`);
      }
    }
  }, [user]);

  const userProfile = useClientProfile(firebase);

  const activeConsultations = useGetActiveChatsWIthProfileId(
    userProfile ? userProfile.userUID : null,
    firebase
  );

  const {
    saveUserAcceptance
  } = useAcceptPersonalDataProcessingAgreementAndTerms(firebase);

  if (user === undefined) {
    // wait until listener fires up
    return null;
  }

  const needToAgreeOnPersonalDataProcessing =
    (!!user && userProfile === null) ||
    (!!user && userProfile && !userProfile.hasAgreedOnPersonalDataProcessing);
  const goToProfile =
    (!!user && userProfile === null) ||
    (!!user && userProfile && !userProfile.sex);

  return (
    <Container>
      <PersonalDataProcessingAgreementModal
        show={needToAgreeOnPersonalDataProcessing}
        handleAccept={saveUserAcceptance}
      />
      {goToProfile && <Redirect to="/userprofile/edit/" />}
      {!goToProfile && <AppNavBar user={user} logout={logout} />}

      <Route path="/" exact component={LoginPage} />
      <Route path="/login/" exact component={LoginPage} />

      <UserProfileContext.Provider value={userProfile}>
        <ActiveConsultationsContext.Provider value={activeConsultations}>
          <Route
            path="/login/verify/"
            component={LoginVerifyEmailLinkPage}
            exact
          />

          <PrivateRoute
            path="/specialities/:slug/"
            component={SpecialistListPage}
            state={{ user: user }}
          />
          <PrivateRoute path="/specialities/" state={{ user: user }} exact>
            <SpecialitiesPage />
          </PrivateRoute>

          <PrivateRoute
            path="/specialist/:specialistId/"
            state={{ user: user }}
          >
            <SpecialistDetailPage />
          </PrivateRoute>

          <PrivateRoute path="/userprofile/edit/" state={{ user: user }}>
            <UserProfileEdit />
          </PrivateRoute>

          <PrivateRoute path="/chats/:chatId" state={{ user: user }}>
            <ChatPage />
          </PrivateRoute>
          <PrivateRoute path="/chats/" state={{ user: user }} exact>
            <ConsultationPage />
          </PrivateRoute>

          <PrivateRoute
            path="/history/:consultationId"
            state={{ user: user }}
            component={HistoryDetailPage}
          />
          <PrivateRoute path="/history/" exact state={{ user: user }}>
            <HistoryPage />
          </PrivateRoute>

          <Route path="/about/" component={AboutPage} />
          <Route path="/privacypolicy/" component={PrivacyPolicyPage} />
        </ActiveConsultationsContext.Provider>
      </UserProfileContext.Provider>
    </Container>
  );
};

export default withRouter(App);
