import React, { useContext } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FirebaseContext } from "../../../Firebase";
import { useGetSpecialists } from "./hooks";

const SpecialistListPage = ({ match }) => {
  const firebase = useContext(FirebaseContext);
  const specialists = useGetSpecialists(match.params.slug, firebase);
  const specialistCards = !!specialists
    ? specialists.map(specialist => (
        <Col key={specialist.id} xs={6} md={3}>
          <SpecialistCard specialist={specialist} />
        </Col>
      ))
    : null;
  //console.log("Specialists", specialists, "Slug: ", match.params.slug);
  return (
    <Row>
      <Col xs={12}>
        <h1>Терапевты</h1>
      </Col>
      <Col>{specialistCards}</Col>
    </Row>
  );
};

export default SpecialistListPage;

const SpecialistCard = ({ specialist }) => {
  const { fullName, id, image, speciality } = specialist;
  const specialityLabel = speciality === "terapevt" ? "Терапевт" : "Педиатр";
  return (
    <Card>
      <Card.Img variant="top" src={image} thumbnail />
      <Card.Body>
        <Card.Title>{fullName}</Card.Title>
        <Card.Text>{specialityLabel}</Card.Text>
        <Link className="btn btn-primary" to={`/specialist/${id}/`}>
          Подробнее
        </Link>
      </Card.Body>
    </Card>
  );
};
