import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";
import logo from "../../MkLogoSimple.svg";

const AppNavBar = ({ user, logout }) => {
  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <img
          src={logo}
          width="150"
          height="33"
          className="d-inline-block align-top"
          alt="MedKeeper logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {!user && (
          <Nav className="mr-auto">
            <Nav.Link as={NavLink} className="btn btn-primary" to="/login/">
              Войти
            </Nav.Link>
          </Nav>
        )}
        {user && (
          <Fragment>
            <Nav className="mr-auto">
              <Nav.Link as={NavLink} to="/specialities/">
                Специалисты
              </Nav.Link>
              <Nav.Link as={NavLink} to="/chats/">
                Чаты
              </Nav.Link>
              <Nav.Link as={NavLink} to="/history/">
                История
              </Nav.Link>
            </Nav>
            <Button className="btn btn-outlined-primary" onClick={logout}>
              Выйти
            </Button>
          </Fragment>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavBar;
