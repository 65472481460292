import { useEffect, useState } from "react";

const useGetSpecialities = firebase => {
  const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    if (firebase) {
      const unsubscribe = firebase.db
        .collection("speciality")
        .onSnapshot(querySnapshot => {
          let specialities = [];
          querySnapshot.forEach(docRef => {
            specialities.push({ id: docRef.id, ...docRef.data() });
          });
          setSpecialities(specialities);
        });
      return unsubscribe;
    }
  }, [firebase]);
  return specialities;
};

export { useGetSpecialities };
