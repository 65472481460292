import React from "react";
import { Row, Col } from "react-bootstrap";

const AboutPage = () => (
  <Row>
    <Col>
      <h1>О компании "Medkeeper"</h1>
      <p>
        Компания Medkeeper основана в 2012 году и занимается технологиями в
        области оказания медицинских услуг.
      </p>
      <p>ООО "Современные Технологии" 2019, Садовническая наб. 8с1, Москва </p>
    </Col>
  </Row>
);

export default AboutPage;
