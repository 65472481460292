import React from "react";
import MessageList from "../MessageList";
import "./Messenger.css";

export default function Messenger(props) {
  const {
    messageList,
    sendMessage,
    finishConsultation,
    specialist,
    handleNewFilesChosen,
    isSendingImage
  } = props;

  return (
    <div className="messenger">
      <div className="scrollable content">
        <MessageList
          messageList={messageList}
          sendMessage={sendMessage}
          finishConsultation={finishConsultation}
          specialist={specialist}
          handleNewFilesChosen={handleNewFilesChosen}
          isSendingImage={isSendingImage}
        />
      </div>
    </div>
  );
}
