import React, { Fragment, useContext } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import ChatCard from "../../Components/ChatCard";

import { FirebaseContext } from "../../Firebase";

import { useFinishConsultation } from "./hooks";
import ActiveConsultationsContext from "../../context";

const ConsultationPage = ({ location, match, history }) => {
  const firebase = useContext(FirebaseContext);
  const activeConsultations = useContext(ActiveConsultationsContext);

  const { finishConsultationWithId } = useFinishConsultation(firebase, null);

  const activeChatCards = activeConsultations.map(consultation => (
    <ChatCard
      consultationId={consultation.id}
      specialist={consultation.specialist}
      finishConsultation={() => finishConsultationWithId(consultation.id)}
    />
  ));
  if (activeConsultations.length === 0) {
    return (
      <Alert variant="info">
        Активных чатов со специалистами нет. Чтобы найти вашего специалиста
        <Link to="/specialities/"> перейдите в раздел </Link> специальностей.
      </Alert>
    );
  }
  return <Fragment>{activeChatCards}</Fragment>;
};

export default ConsultationPage;
//action onClick={alertClicked}
