import { useEffect, useState } from "react";

const useCreateConsultationWithSpecialist = (
  specialistId,
  boolCreate,
  firebase
) => {
  const [creating, setCreating] = useState(null);
  let [success, setSuccess] = useState(null);
  let [error, setError] = useState(null);

  useEffect(() => {
    if (specialistId && boolCreate) {
      setCreating(true);
      firebase.db
        .collection("commands/")
        .add({
          clientUserId: firebase.auth.currentUser.uid,
          specialistId: specialistId,
          type: "CREATE_CONSULTATION"
        })
        .then(() => {
          setCreating(false);
          setSuccess(true);
          setError(false);
        })
        .catch(error => {
          creating(false);
          setSuccess(false);
          setError(true);
        });
    }
  }, [specialistId, boolCreate]);

  return { creating, success, error };
};
export { useCreateConsultationWithSpecialist };

const useGetSpecialist = (id, firebase) => {
  const [specialist, setSpecialist] = useState(null);

  useEffect(() => {
    if (id) {
      firebase.db
        .doc(`publicSpecialistProfile/${id}`)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            setSpecialist({ id: docSnapshot.id, ...docSnapshot.data() });
          }
        });
    }
  }, [id]);
  return specialist;
};
export { useGetSpecialist };
