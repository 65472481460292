import React, { useContext, Fragment } from "react";
import { Row, Col } from "react-bootstrap";

import { FirebaseContext } from "../../../Firebase";
import { getTimeLabelFromFirestoreTimestamp } from "../../../utils";
import { useGetSpecialistRecommendation } from "./hooks";

const HistoryDetailPage = ({ match }) => {
  const firebase = useContext(FirebaseContext);

  const { consultationId } = match.params;
  const recommendations = useGetSpecialistRecommendation(
    firebase,
    consultationId
  );
  const date = recommendations
    ? getTimeLabelFromFirestoreTimestamp(recommendations.createdAt)
    : "";

  return (
    <Row>
      <Col>
        <h1>Рекомендации от {date}</h1>
        {recommendations ? (
          <SpecialistRecommendation
            recommendations={recommendations.recomendationData}
          />
        ) : (
          <p>Пока тут пусто</p>
        )}
      </Col>
    </Row>
  );
};

export default HistoryDetailPage;

const SpecialistRecommendation = props => {
  return (
    <Fragment>
      <h4>Анализы</h4>
      <p>
        {props.recommendations.testsRecommendation
          ? props.recommendations.testsRecommendation
          : "Рекомендации нет"}
      </p>
      <h4>Консультации</h4>
      <p>
        {props.recommendations.realConsultationRecommendation
          ? props.recommendations.realConsultationRecommendation
          : "Рекомендации нет"}
      </p>
      <h4>Общее</h4>
      <p>
        {props.recommendations.otherRecommendation
          ? props.recommendations.otherRecommendation
          : "Рекомендации нет"}
      </p>
    </Fragment>
  );
};
