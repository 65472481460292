import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const PersonalDataProcessingAgreementModal = ({ show, handleAccept }) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const handleChange = e => setCheckboxChecked(e.target.checked);
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Согласие с политикой обработки персональных данных
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Режим внутреннего тестирования!</h4>
        <p>
          Внимание, сервис (сайт, веб-приложение) находится в режиме внутреннего
          тестирования. Если вы случайно зашли на этот сайт и/или приложение,
          покиньте его. Продолжение работы с этим сервисом допустимо только в
          случае если вы получили электронное приглашение с одного из почтовых
          адресов компании.
        </p>
        <p>
          Сервис собирает персональные данные в виде имени (в свободной форме),
          даты рождения и пола с целью предоставления более качественной
          информационной услуги. Эти данные могут быть переданы партнерам
          сервиса или иным третьим сторонам для обработки и улучшения качества
          предоставления сервиса, в коммерческих целях и в иных случаях не
          противоречащих занонодательству РФ. Продолжив пользование сервисом, вы
          выражается свое согласие с политикой обработки персональных данных.
        </p>
        <Form.Group controlId="userAgreeChackbox">
          <Form.Check
            value={checkboxChecked}
            onChange={handleChange}
            type="checkbox"
            label="Я согласен с политикой обрабоотки персональных данных и готов предоставить эти данные."
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!checkboxChecked} onClick={handleAccept}>
          Согласиться
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonalDataProcessingAgreementModal;
