import React, { useEffect, useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "./Compose.css";
import ToolbarButton from "../ToolbarButton";

export default function Compose(props) {
  const { sendMessage } = props;
  const [messageValue, setMessageValue] = useState("");
  const [mesageToSend, setMessageToSend] = useState("");

  const proxyValueFunction = val => setMessageValue(val);
  const proxySendFunction = () => {
    console.log("Will send message");
    setMessageToSend(messageValue);
    setMessageValue("");
  };

  const _handleKeyDown = e => {
    if (e.key === "Enter") {
      proxySendFunction();
    }
  };

  useEffect(() => {
    if (!!mesageToSend) {
      sendMessage(mesageToSend);
      setMessageToSend("");
    }
  }, [mesageToSend]);

  return (
    <div className="compose">
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Наберите сообщение"
          aria-label="Поле для набора сообщения"
          aria-describedby="basic-addon2"
          onKeyDown={_handleKeyDown}
          onChange={e => proxyValueFunction(e.target.value)}
          value={messageValue}
        />
        <InputGroup.Append>
          <Button
            variant={messageValue.length > 0 ? "primary" : "outline-secondary"}
            onClick={proxySendFunction}
            disabled={messageValue.length === 0}
          >
            <i class="ion-ios-send"></i>
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <label className="btn btn-primary mb-3 ml-2">
        <i class="ion-ios-camera"></i>

        <input
          disabled={props.isSendingImage}
          type="file"
          accept="image/*"
          hidden
          onChange={e => {
            props.handleNewFilesChosen(e.target.files);
          }}
        />
      </label>
    </div>
  );
}
