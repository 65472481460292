import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import Compose from "../Compose";
import Toolbar from "../Toolbar";
import ToolbarButton from "../ToolbarButton";
import Message from "../Message";
import moment from "moment";

import "./MessageList.css";

import { FirebaseContext } from "../../../Firebase";

export default function MessageList(props) {
  const firebase = useContext(FirebaseContext);

  const [finishClicked, setFinishClicked] = useState(false);
  const handleFinisheClicked = () => {
    setFinishClicked(true);
    props.finishConsultation();
  };

  const MY_USER_ID =
    firebase && firebase.auth && firebase.auth.currentUser
      ? firebase.auth.currentUser.uid
      : null;
  const {
    messageList: messages,
    sendMessage,
    specialist,
    handleNewFilesChosen,
    isSendingImage
  } = props;

  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author === MY_USER_ID;
      let currentMoment = current.timestamp
        ? moment.unix(current.timestamp.seconds)
        : moment();
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment.unix(previous.timestamp.seconds);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = next.timestamp
          ? moment.unix(next.timestamp.seconds)
          : moment();
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };

  return (
    <div className=" d-flex flex-column h-100 justify-content-between message-list">
      <Toolbar
        title={specialist.fullName}
        rightItems={[
          <Button
            variant="outline-danger"
            size="sm"
            onClick={handleFinisheClicked}
            disabled={finishClicked}
          >
            Завершить
          </Button>
        ]}
      />

      <div className="message-list-container">{renderMessages()}</div>

      <Compose
        sendMessage={sendMessage}
        rightItems={[<ToolbarButton key="photo" icon="ion-ios-camera" />]}
        handleNewFilesChosen={handleNewFilesChosen}
        isSendingImage={isSendingImage}
      />
    </div>
  );
}
