import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};
class Firebase {
  constructor() {
    firebase.initializeApp(config);
    // AUTH
    this.auth = firebase.auth();
    this.sendSignInLinkToEmail = email =>
      this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
    this.signInWithEmailLink = (email, href) =>
      this.auth.signInWithEmailLink(email, href);
    this.doSignOut = () => this.auth.signOut();

    // DB
    this.db = firebase.firestore();
    this.functions = firebase.functions();
    this.FieldValue = firebase.firestore.FieldValue;

    // Storage
    this.storage = firebase.storage();
  }
}
export default Firebase;

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: process.env.REACT_APP_LOGIN_VERIFY_REDIRECT,
  // This must be true.
  handleCodeInApp: true
};
