import React, { useState, useContext, Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { FirebaseContext } from "../../Firebase";
import { useSaveClientData } from "./hooks";

const UserProfileEdit = () => {
  const firebase = useContext(FirebaseContext);
  let history = useHistory();

  const [nameValue, setNameValue] = useState("");
  const [birthdayValue, setBirthdayValue] = useState("");
  const [sexValue, setSexValue] = useState("");

  const { updateTheProfile } = useSaveClientData();

  return (
    <Fragment>
      <Row>
        <Col>
          <h1>Ваши данные</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form
            onSubmit={e => {
              e.preventDefault();
              const clientData = {
                name: nameValue,
                birthday: birthdayValue,
                sex: sexValue
              };
              updateTheProfile({ clientData, firebase });
              history.replace("/specialities");
            }}
          >
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Как к вам обращаться?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Имя"
                onChange={e => setNameValue(e.target.value)}
              />
              <Form.Text className="text-muted">
                Указывать полное имя не обязательно.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Дата рождения</Form.Label>
              <Form.Control
                type="date"
                placeholder="ДД.ММ.ГГ"
                onChange={e => {
                  console.log("New date value: ", e.target.value);
                  setBirthdayValue(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Label>Пол</Form.Label>
              <Form.Check
                type="radio"
                label="М"
                onChange={() => setSexValue("M")}
              />
              <Form.Check
                type="radio"
                label="Ж"
                onChange={() => setSexValue("F")}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Сохранить
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};
export default UserProfileEdit;
