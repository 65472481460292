import React, { useContext, Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FirebaseContext } from "../../Firebase";
import { UserProfileContext } from "../../context";
import { getTimeLabelFromFirestoreTimestamp } from "../../utils";
import { useGetHistoryData } from "./hooks";

const HistoryPage = () => {
  const firebase = useContext(FirebaseContext);
  const userProfile = useContext(UserProfileContext);

  const { historyDataList } = useGetHistoryData(firebase, userProfile);
  const historyCards = historyDataList.map(item => (
    <ConsultationHistory
      specialist={item.specialist}
      consultationId={item.id}
      timestamp={item.finishedAt}
    />
  ));
  return (
    <Fragment>
      <Row>{historyCards}</Row>
    </Fragment>
  );
};

export default HistoryPage;

const ConsultationHistory = ({ specialist, consultationId, timestamp }) => {
  const moment = require("moment");
  const timestampLabel = timestamp
    ? getTimeLabelFromFirestoreTimestamp(timestamp)
    : "";

  return (
    <Col xs={12} md={6} lg={4} className="mb-2">
      <Link
        to={{
          pathname: `/history/${consultationId}`,
          state: { specialist, consultationId }
        }}
      >
        <Card>
          <Card.Body>
            <Card.Title>{specialist.fullName}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {timestampLabel}
            </Card.Subtitle>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
};
