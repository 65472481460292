import * as moment from "moment";
import "moment/locale/ru";

export const getTimeLabelFromFirestoreTimestamp = timestamp => {
  moment.locale("ru");

  const timestampLabel = timestamp
    ? moment.unix(timestamp.seconds).format("Do MMMM YYYY")
    : "";
  return timestampLabel;
};
