import React, { Fragment, useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FirebaseContext } from "../../Firebase";
import { useGetSpecialities } from "./hooks";

const SpecialitiesPage = () => {
  const firebase = useContext(FirebaseContext);
  const specialities = useGetSpecialities(firebase);

  const specialityOptions = !!specialities
    ? specialities.map(speciality => (
        <Col>
          <SpecialityCard speciality={speciality} />
        </Col>
      ))
    : null;
  return (
    <Fragment>
      <Row>{specialityOptions}</Row>
    </Fragment>
  );
};

export default SpecialitiesPage;

const SpecialityCard = ({ speciality }) => {
  const { name, slug, image, description } = speciality;
  return (
    <Card>
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <Link className="btn btn-primary" to={`/specialities/${slug}/`}>
          Выбрать специалиста
        </Link>
      </Card.Body>
    </Card>
  );
};
