import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const LoginForm = ({ submitEmailValue }) => {
  const [emailValue, setEmailValue] = useState("");

  return (
    <Form
      onSubmit={e => {
        submitEmailValue(emailValue);
        e.preventDefault();
      }}
      className="mt-4"
    >
      <Form.Group controlId="formBasicEmail">
        <Form.Control
          size="lg"
          type="email"
          placeholder="Ваш email"
          onChange={e => setEmailValue(e.target.value)}
          value={emailValue}
        />
        <Form.Text className="text-muted">
          После отправки Email вы получите письмо с ссылкой для входа в
          приложение.
        </Form.Text>
      </Form.Group>

      <Button variant="primary" type="submit">
        Отправить
      </Button>
    </Form>
  );
};

export default LoginForm;
