import React, { useContext } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useLocation, useLocalStorage } from "react-use";
import { useSignInWithEmailLink } from "./hooks";
import { FirebaseContext } from "../../Firebase";

const LoginVerifyEmailLinkPage = () => {
  const firebase = useContext(FirebaseContext);
  const location = useLocation();
  const [localStorageEmail, setLocalStorageEmail] = useLocalStorage(
    "LS_USER_EMAIL"
  );
  const { loading, error } = useSignInWithEmailLink(
    location.href,
    localStorageEmail,
    firebase
  );
  return (
    <Row>
      <Col>
        <h1>Вход по ссылке</h1>
        {loading && <p>Loading...</p>}
        {error && <Alert variant="danger">Ошибка</Alert>}
        {!localStorageEmail && (
          <Alert variant="warning">
            Вход возможен только с устройства на котором вы ввели адрес почты.
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default LoginVerifyEmailLinkPage;
