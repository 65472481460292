import React from "react";
import "./ToolbarButton.css";

export default function ToolbarButton(props) {
  const { icon } = props;
  return (
    <span onClick={props.onClick}>
      <i className={`toolbar-button ${icon}`} />
    </span>
  );
}
