import { useState, useEffect } from "react";

export const useGetSpecialistRecommendation = (firebase, consultationId) => {
  const [recommendation, setRecommendation] = useState(null);

  useEffect(() => {
    if ((firebase, consultationId)) {
      firebase.db
        .collection(`chats/${consultationId}/specialistRecommendation`)
        .where("isPendingSpecialist", "==", false)
        .get()
        .then(qSnapshot => {
          if (!qSnapshot.empty) {
            const docRef = qSnapshot.docs[0];
            setRecommendation({ id: docRef.id, ...docRef.data() });
          } else {
            setRecommendation(null);
          }
        })
        .catch(error => console.error(error));
    }
  }, [firebase, consultationId]);

  return recommendation;
};
