import React from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ChatCard = ({ consultationId, specialist, finishConsultation }) => {
  const { fullName, image } = specialist;

  return (
    <Card>
      <Row>
        <Col xs={4}>
          <Image src={image} thumbnail />
        </Col>
        <Col xs={8}>
          <Card.Body>
            <p className="h2">{fullName}</p>
            <Button
              as={Link}
              to={`/chats/${consultationId}`}
              variant="outline-primary"
              block
            >
              Продолжить
            </Button>
            <Button variant="outline-danger" block onClick={finishConsultation}>
              Завершить
            </Button>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default ChatCard;
