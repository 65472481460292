import { useState, useEffect } from "react";

export const useGetHistoryData = (firebase, userProfile) => {
  const [historyDataList, setHistoryDataList] = useState([]);

  useEffect(() => {
    if (firebase && userProfile) {
      firebase.db
        .collection("chats")
        .where("clientId", "==", userProfile.id)
        .where("finished", "==", true)
        .onSnapshot(querySnapshot => {
          let chats = [];
          querySnapshot.forEach(function(doc) {
            chats.push({ id: doc.id, ...doc.data() });
          });
          setHistoryDataList(chats);
        });
    }
  }, [firebase, userProfile]);

  return { historyDataList };
};
