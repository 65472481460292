import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

import Messenger from "../../Components/Chat/Messenger";
import { FirebaseContext } from "../../Firebase";
import ActiveConsultationsContext from "../../context";
import { useSendFiles } from "./hooks";

import {
  useFinishConsultation,
  useGetChatMessages,
  useSendNewMessage
} from "./hooks";

const ChatPage = props => {
  const firebase = useContext(FirebaseContext);
  const activeConsultations = useContext(ActiveConsultationsContext);
  const { chatId } = useParams("consultationId");
  const { handleNewFilesChosen, isSending } = useSendFiles(firebase, chatId);

  const chatData = activeConsultations.find((element, index, array) => {
    return element.id === chatId;
  });

  const [newMessageToSend, setNewMessageToSend] = useState("");
  const { sending, sentOk, error } = useSendNewMessage(
    firebase,
    newMessageToSend,
    chatId
  );
  const chatMessages = useGetChatMessages(firebase, chatId);

  const {
    finishConsultation,
    finishConsultationWithId
  } = useFinishConsultation(firebase, chatId);

  if (!chatData) {
    return <Alert variant="warning">Этот чат со специалистом завершен!</Alert>;
  }
  return (
    <Messenger
      messageList={chatMessages}
      sendMessage={setNewMessageToSend}
      handleNewFilesChosen={handleNewFilesChosen}
      isSendingImage={isSending}
      finishConsultation={finishConsultation}
      specialist={chatData.specialist}
    />
  );
};

export default ChatPage;
