const useSaveClientData = () => {
  const updateTheProfile = async ({ clientData, firebase }) => {
    return await firebase.db
      .doc(`clientProfile/${firebase.auth.currentUser.uid}`)
      .set({ userUID: firebase.auth.currentUser.uid, ...clientData });
  };

  return { updateTheProfile };
};
export { useSaveClientData };
