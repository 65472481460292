import React from "react";
import { Row, Col } from "react-bootstrap";

const PrivacyPolicyPage = () => (
  <Row>
    <Col>
      <h1>Пользовательское соглашение</h1>
      <p>
        Тут хорошобы чтобы юридически грамотные люди написали пользовательское
        соглашение.
      </p>
      <p>ООО "Современные Технологии" 2019, Садовническая наб. 8с1, Москва </p>
    </Col>
  </Row>
);

export default PrivacyPolicyPage;
