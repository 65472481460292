import React, { useContext, useState } from "react";
import { Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { FirebaseContext } from "../../../Firebase";
import { useGetSpecialist, useCreateConsultationWithSpecialist } from "./hooks";
import ActiveConsultationsContext from "../../../context";

const SpecialistDetailPage = props => {
  const firebase = useContext(FirebaseContext);
  const activeConsultations = useContext(ActiveConsultationsContext);

  const { specialistId } = useParams();
  const specialist = useGetSpecialist(specialistId, firebase);

  //this is the object containing the id property || undefined
  const hasActiveConsultationWithSpecialistWithId = specialistId
    ? activeConsultations.find(el => el.specialistId === specialistId)
    : false;

  const [requestConsultation, setRequestConsultation] = useState(false);
  const { creating, success, error } = useCreateConsultationWithSpecialist(
    specialistId,
    requestConsultation,
    firebase
  );
  const onGetConsultationClicked = () => setRequestConsultation(true);

  if (!specialist) return null;
  const educationLists = specialist.education.map(edu => (
    <EducationSnippet data={edu} />
  ));

  return (
    <Row>
      <Col xs={12}>
        <h1>
          {specialist.fullName}{" "}
          <span className="text-muted">
            <small>специалист</small>
          </span>
        </h1>
      </Col>
      <Col xs={12}>
        <h2>Образование</h2>
        {educationLists}
      </Col>
      <Col>
        {specialistId && !hasActiveConsultationWithSpecialistWithId && (
          <Button
            variant="primary"
            onClick={onGetConsultationClicked}
            block
            size="lg"
            disabled={creating || success}
          >
            {creating && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Начать чат
          </Button>
        )}
        {specialistId && hasActiveConsultationWithSpecialistWithId && (
          <Alert variant="primary">
            Активный чат создан, вы можете общаться со специалистом.
            <Link
              variant="primary"
              to={`/chats/${hasActiveConsultationWithSpecialistWithId.id}`}
            >
              {" "}
              Перейти в чат
            </Link>
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default SpecialistDetailPage;

const EducationSnippet = ({ data }) => {
  return (
    <dl className="mt-4">
      <dt>Год получения</dt>
      <dd>{data.endYear}</dd>
      <dt>Название учреждения</dt>
      <dd>{data.schoolName}</dd>
      <dt>Тип обучения</dt>
      <dd>{data.specialization}</dd>
      <dt>Специальность</dt>
      <dd>{data.type}</dd>
    </dl>
  );
};
