import { useEffect, useState } from "react";

export const useGetChatMessages = (firebase, chatId) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (firebase && firebase.auth && firebase.auth.currentUser && chatId) {
      firebase.db
        .collection(`chats/${chatId}/messages`)
        .orderBy("timestamp")
        .onSnapshot(querySnapshot => {
          let messages = [];
          querySnapshot.forEach(function(doc) {
            messages.push({ id: doc.id, ...doc.data() });
          });
          setMessages([...messages]);
        });
    }
  }, [firebase, firebase.auth, firebase.auth.currentUser, chatId]);

  return messages;
};

export const useSendNewMessage = (firebase, message, chatId) => {
  const [sending, setSending] = useState(false);
  const [sentOk, setSentOk] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    console.log("useSendNewMessage", message, chatId);

    if (sentOk && !message) setSentOk(undefined);

    if (firebase && message && chatId) {
      setSending(true);
      console.log("useSendNewMessage Will send");
      firebase.db
        .collection(`chats/${chatId}/messages`)
        .add({
          author: firebase.auth.currentUser.uid,
          message: message,
          timestamp: firebase.FieldValue.serverTimestamp()
        })
        .then(() => {
          setSending(false);
          setSentOk(true);
          setError(undefined);
        })
        .catch(error => {
          setSending(false);
          setSentOk(false);
          setError(error);
        });
    }
  }, [firebase, message, chatId]);
  return { sending, sentOk, error };
};

export const useFinishConsultation = (firebase, chatId) => {
  const [id, setId] = useState(null);
  useEffect(() => {
    setId(chatId);
  }, [chatId, firebase]);

  const finishConsultation = async () => {
    await firebase.db.collection(`chats/${id}/actions`).add({
      type: "FINISH_CONSULTATION",
      created_by: firebase.auth.currentUser.uid,
      isClient: true,
      timestamp: firebase.FieldValue.serverTimestamp()
    });
  };

  const finishConsultationWithId = async consultationId => {
    await firebase.db.collection(`chats/${consultationId}/actions`).add({
      type: "FINISH_CONSULTATION",
      created_by: firebase.auth.currentUser.uid,
      isClient: true,
      timestamp: firebase.FieldValue.serverTimestamp()
    });
  };

  return { finishConsultation, finishConsultationWithId };
};

const useSendFiles = (firebase, chatId) => {
  const [isSending, setIsSending] = useState(false);

  const handleNewFilesChosen = async files => {
    console.log(files);
    setIsSending(true);
    try {
      const docRef = await firebase.db
        .collection(`chats/${chatId}/messages`)
        .add({
          author: firebase.auth.currentUser.uid,
          message: "",
          type: "image",
          url: "",
          timestamp: firebase.FieldValue.serverTimestamp()
        });

      let storageRef = firebase.storage.ref();
      const chatImageRef = storageRef.child(`chat/${chatId}/${docRef.id}`);
      const imageSnapshot = await chatImageRef.put(files[0]);

      const downloadURL = await imageSnapshot.ref.getDownloadURL();

      const ss = docRef.update({ url: downloadURL });
      setIsSending(false);
    } catch (e) {
      console.error("SL error: ", e);
      setIsSending(false);
    }
  };
  return { isSending, handleNewFilesChosen };
};

export { useSendFiles };
