import { useEffect, useState } from "react";

const useListenUserAuthState = auth => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    if (auth) {
      return auth.onAuthStateChanged(function(user) {
        setUser(user);
      });
    }
  }, [auth]);
  return user;
};

export { useListenUserAuthState };

const useHasAnyRequest = firebase => {
  const [hasRequest, setHasRequest] = useState(false);

  useEffect(() => {
    if (firebase && firebase.auth.currentUser) {
      firebase.db
        .collection("activeServiceRequest")
        .where("isActive", "==", true)
        .where("clientId", "==", firebase.auth.currentUser.uid)
        .onSnapshot(querySnapshot => {
          console.log("useHasAnyRequest ", !querySnapshot.empty);
          setHasRequest(!querySnapshot.empty);
        });
    }
  }, [firebase, firebase.auth.currentUser]);

  return hasRequest;
};

const useHasConsultation = firebase => {
  const [hasConsultationWithID, setHasConsultationWithID] = useState(undefined);

  useEffect(() => {
    if (firebase && firebase.auth.currentUser) {
      firebase.db
        .collection("consultation")
        .where("isActive", "==", true)
        .where("clientUID", "==", firebase.auth.currentUser.uid)
        .onSnapshot(querySnapshot => {
          querySnapshot.empty
            ? setHasConsultationWithID(undefined)
            : setHasConsultationWithID(querySnapshot.docs[0].id);
        });
    }
  }, [firebase, firebase.auth.currentUser]);

  return hasConsultationWithID;
};

const useClientProfile = firebase => {
  const [clientProfile, setClientProfile] = useState(undefined);
  useEffect(() => {
    if (firebase && firebase.auth.currentUser) {
      firebase.db
        .collection("clientProfile")
        .doc(firebase.auth.currentUser.uid)
        .onSnapshot(docSnapshot => {
          if (!docSnapshot.exists) setClientProfile(null);
          else setClientProfile({ id: docSnapshot.id, ...docSnapshot.data() });
        });
    }
  }, [firebase, firebase.auth.currentUser]);
  return clientProfile;
};

export { useHasAnyRequest, useHasConsultation, useClientProfile };

export const useGetActiveChatsWIthProfileId = (id, firebase) => {
  const [activeChats, setActiveChats] = useState([]);

  useEffect(() => {
    if (!!firebase && !!id) {
      firebase.db
        .collection("chats")
        .where("clientId", "==", id)
        .where("finished", "==", false)
        .onSnapshot(querySnapshot => {
          let tempArr = [];
          querySnapshot.forEach(doc => {
            tempArr.push({ id: doc.id, ...doc.data() });
          });
          setActiveChats([...tempArr]);
        });
    }
  }, [id]);
  return activeChats;
};

export const useAcceptPersonalDataProcessingAgreementAndTerms = firebase => {
  const saveUserAcceptance = async () => {
    if (!!firebase) {
      await firebase.db
        .collection("clientProfile")
        .doc(firebase.auth.currentUser.uid)
        .set({ hasAgreedOnPersonalDataProcessing: true }, { merge: true });
    }
  };

  return { saveUserAcceptance };
};
